@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .square {
        aspect-ratio: 1 / 1;
    }

    .transition-full {
        transition: all 0.5s linear;
    }

    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
    }

    .font-gilroy-400 {
        font-family: 'Gilroy Regular', sans-serif;
        font-weight: 400;
    }

    .font-gilroy-500 {
        font-family: 'Gilroy Medium', sans-serif;
        font-weight: 500;
    }

    .font-gilroy-600 {
        font-family: 'Gilroy SemiBold', sans-serif;
        font-weight: 600;
    }

    .font-gilroy-700 {
        font-family: 'Gilroy Bold', sans-serif;
        font-weight: 700;
    }

    .font-gilroy-800 {
        font-family: 'Gilroy ExtraBold', sans-serif;
        font-weight: 800;
    }

    .font-gilroy-900 {
        font-family: 'Gilroy Black', sans-serif;
        font-weight: 900;
    }

    .thumb,
    .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        outline: none;
        z-index: 10;
    }

    .thumb::-webkit-slider-thumb {
        background-color: #FFD400;
        border: 3px solid #A4A9B4;
        border-radius: 50%;
        cursor: pointer;
        height: 14px;
        width: 14px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }
}


@font-face {
    font-family: 'Gilroy Regular';
    src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Medium';
    src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy SemiBold';
    src: url('./assets/fonts/Gilroy-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Bold';
    src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy ExtraBold';
    src: url('./assets/fonts/Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy Black';
    src: url('./assets/fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

a {
    color: inherit;
    text-decoration: none;
}

textarea:focus {
    outline: none;
}

input:focus {
    outline: none;
}

#root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}
